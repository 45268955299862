import Vue from "vue";
import VueRouter from "vue-router";

import Main from "@/pages/mainPage.vue";
import servicesPage from "@/pages/servicesPage.vue";
import pricesPage from "@/pages/pricesPage.vue";
import doctorsPage from "@/pages/doctorsPage.vue";
import newsPromoPage from "@/pages/newsPromoPage.vue";
import addPage from "@/pages/addPage.vue";
import infoPage from "@/pages/infoPage.vue";
import allServices from "@/components/servicesPage/items/allServices.vue";
import allCatServices from "@/components/servicesPage/categories/allCat.vue";
import allPrices from "@/components/pricesPage/items/allPrices.vue";
import allDoctors from "@/components/doctorsPage/items/allDoctors.vue";
import allCatDoctors from "@/components/doctorsPage/categories/allCat.vue";
import allNews from "@/components/newsPromoPage/newsItems/allNews.vue";
import allPromo from "@/components/newsPromoPage/promoItems/allPromo.vue";
import Reviews from "@/components/reviews/allReviews.vue"

// import catItem from "@/components/doctorsPage/categories/catItem.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: 'main',
    path: '/',
    component: Main,
  },
  {
    name: 'info',
    path: '/info',
    component: infoPage,
  },
  {
    name: 'add',
    path: '/add',
    component: addPage,
  },
  {
    name: 'services',
    path: '/services',
    component: servicesPage,
    children: [
      {
        name: 'services_groups',
        path: 'services_groups',
        component: allCatServices,
      },
      {
        name: 'all_services',
        path: 'all_services',
        component: allServices,
      }
    ]
  },
  {
    name: 'prices',
    path: '/prices',
    component: pricesPage,
    children: [
      {
        name: 'all_prices',
        path: 'all_prices',
        component: allPrices,
      }
    ]
  },
  {
    name: 'doctors',
    path: '/doctors',
    component: doctorsPage,
    children: [
      {
        name: 'specialities',
        path: 'specialities',
        component: allCatDoctors,
      },
      {
        name: 'all_doctors',
        path: 'all_doctors',
        component: allDoctors,
      },

    ]
  },
  {
    name: 'reviews',
    path: '/reviews',
    component: Reviews,
  },
  // {
  //   path: '/doctors/sp',
  //   componenet: catItem,
  // },
  {
    name: 'newspromo',
    path: '/newspromo',
    component: newsPromoPage,
    children: [
      {
        name: 'all_news',
        path: 'all_news',
        component: allNews,
      },
      {
        name: 'all_promo',
        path: 'all_promo',
        component: allPromo,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // if (store.getters.isAuthorized) {
  if (localStorage.getItem('isAuth')) {
    next();
  } else {
  }
})

export default router;