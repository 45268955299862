<template>
  <div>
    <h4 class="current-page__subtitle">{{ activeMenuItem.titleRu }}</h4>
    <div class="services__filter">
      <search-input v-model="searchQuerry" :label="'Поиск...'"></search-input>
    </div>
    
    <main-button class="mt" @click.native="createPageFormVisible = true">Добавить</main-button>
    <create-page-item v-if="createPageFormVisible" @close="createPageFormVisible = false" @create="postContent"/>
     
    <div class="current-page__doc-list current-page__category-list">
      <page-list-item v-for="item, i in filteredContent" :key="i" :item="item" @remove="deleteContent" @edit="putContent" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getItems, deleteItems, postItems, putItems } from "@/api/lists";
import { getCategories } from "@/api/categories";
import pageListItem from "@/components/addPage/pageListItem.vue";
import createPageItem from "@/components/addPage/createPageItem.vue";

export default {
  components: {
    pageListItem,
    createPageItem
  },
  data() {
    return {
      reqURL: 'https://admin.sunkarpremium.kz/api/pages',
      content: [],
      createPageFormVisible: false,
      searchQuerry: "",
    };
  },
  mounted() {
    this.getContent();
  },
  computed: {
    ...mapGetters(["activeMenuItem", "baseURL", "isLoading"]),

    filteredContent() {
      return [... this.content].filter(i => {
        if (i.title.toLowerCase().includes(this.searchQuerry.toLowerCase())) {
          return i.title.toLowerCase().includes(this.searchQuerry.toLowerCase())
        }
        return i.body.toLowerCase().includes(this.searchQuerry.toLowerCase())
      })
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),
    
    async getContent() {
      this.MUT_setIsLoading(true);

      try {
        const response = await getItems(this.reqURL + '/getPages.php', {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.content = response.data.results;
        }
      } catch(e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
    async deleteContent(id) {
      try {
        const response = await postItems(this.reqURL + '/deletePage.php', {"id": id}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.getContent();
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {}
    },
    async putContent(item) {
      try {
        const response = await postItems(this.reqURL + '/updatePage.php', item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken'),
          }
        })
        if (response.status == 200) {
          this.getContent();
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {}
    },
    async postContent(item) {
      try {
        const response = await postItems(this.reqURL + '/createPage.php', item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken'),
          }
        })
        if (response.status == 200) {
          this.getContent();
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.createPageFormVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.mt {
  margin-top: 16px;
}
</style>