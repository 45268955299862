<template>
  <div class="modal" v-if="show" @click="$emit('hide', false)">
    <div class="modal__body" @click.stop>
      <button type="button" class="modal__close" @click="$emit('hide', false)"><img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon"></button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "main-modal",
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
  }
};
</script>

<style scoped>
</style>
