<template>
  <div class="dib transform-rotate">
    <svg style="" width="10" height="6" viewBox="0 0 10 6" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.833008 0.916504L4.99967 5.08317L9.16634 0.916504H0.833008Z" />
    </svg>
    <slot style="margin-left: 8px;"></slot>
  </div>
</template>

<script>
export default {
  name: "mainUpward",
};
</script>

<style scoped>
</style>
