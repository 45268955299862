<template>
  <div>
    <h4 class="current-page__subtitle">{{ activeSubmenuItem.titleRu }}</h4>
    <div class="services__filter">
      <search-input v-model="searchQuerry" :label="'Поиск...'"></search-input>
    </div>

    <main-button class="mt" @click.native="catFormVisible = true">Добавить</main-button>
    <create-cat-item v-if="catFormVisible" @close="catFormVisible = false" @call="getContent()"/>

    <div class="current-page__category-list">
      <cat-list-item v-for="item, i in filteredContent" :key="i" :item="item" @call="getContent()" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getCategories } from "@/api/categories";
import catListItem from "@/components/doctorsPage/categories/catListItem.vue";
import createCatItem from "@/components/doctorsPage/categories/createCatItem.vue";

export default {
  components: {
    catListItem,
    createCatItem
  },
  data() {
    return {
      content: [],
      catFormVisible: false,
      searchQuerry: "",
    };
  },
  mounted() {
    this.getContent();
  },
  computed: {
    ...mapGetters(["activeSubmenuItem", "baseURL", "isLoading"]),

    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/doctorsCategories'
    },
    filteredContent() {
      return [... this.content].filter(i => {
        if (i.name.toLowerCase().includes(this.searchQuerry.toLowerCase())) {
          return i.name.toLowerCase().includes(this.searchQuerry.toLowerCase())
        }
        return i.description.toLowerCase().includes(this.searchQuerry.toLowerCase())
      })
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),
    
    async getContent() {
      this.MUT_setIsLoading(true);

      try {
        const response = await getCategories(this.reqURL + '/getCategories.php', {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        });        
        if (response.status == 200) {
          this.content = response.data.results;
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
  }
};
</script>

<style scoped>
</style>