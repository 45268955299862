<template>
  <div>
    <h4 class="current-page__subtitle">{{ activeSubmenuItem.titleRu }}</h4>
    <div class="services__filter">
      <search-input v-model="searchQuerry" :label="'Поиск...'"></search-input>
    </div>

    <main-button class="mt" @click.native="createNewsFormVisible = true">Добавить</main-button>
    <create-news-item v-if="createNewsFormVisible" @close="createNewsFormVisible = false" @call="getContent()"/>
     
    <div class="current-page__doc-list current-page__category-list">
      <news-list-item v-for="item, i in filteredContent" :key="i" :item="item" @call="getContent()"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getItems } from "@/api/lists";
import newsListItem from "@/components/newsPromoPage/newsItems/newsListItem.vue";
import createNewsItem from "@/components/newsPromoPage/newsItems/createNewsItem.vue";

export default {
  components: {
    newsListItem,
    createNewsItem
  },
  data() {
    return {
      content: [],
      createNewsFormVisible: false,
      searchQuerry: "",
    };
  },
  mounted() {
    this.getContent();
  },
  computed: {
    ...mapGetters(["activeSubmenuItem", "baseURL", "isLoading"]),

    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/news'
    },
    filteredContent() {
      return [... this.content].filter(i => {
        if (i.title.toLowerCase().includes(this.searchQuerry.toLowerCase())) {
          return i.title.toLowerCase().includes(this.searchQuerry.toLowerCase())
        }
        return i.body.toLowerCase().includes(this.searchQuerry.toLowerCase())
      })
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    async getContent() {
      this.MUT_setIsLoading(true);

      try {
        const response = await getItems(this.reqURL + '/getNews.php', {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.content = response.data.results.filter(i => i.articleType !== '2');
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
  },
};
</script>

<style scoped>
</style>