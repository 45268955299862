<template>
  <div class="modal-static">
    <div>
      <h5 class="modal__title mt0">Добавить сервис</h5>

      <form action="#" class="modal__form" @submit.prevent>
        <new-input v-model="creatingServiceItem.name" :label="'Сервис Ру'" :required="true"></new-input>
        <new-input v-model="creatingServiceItem.nameKaz" :label="'Сервис Каз'" :required="true"></new-input>
        <new-textarea v-model="creatingServiceItem.description" :label="'Описание Ру'" :required="true"></new-textarea>
        <new-textarea v-model="creatingServiceItem.descriptionKaz" :label="'Описание Каз'" :required="true"></new-textarea>
        <main-select :options="categories" :value="selectedOption" @change="changeOption" :required="true" :label="'Услуга'"></main-select>

        <div class="dfsb">
          <main-button class="mt" @click.native="createServiceItem">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('close')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { postItems } from "@/api/lists";

export default {
  data() {
    return {
      creatingServiceItem: {
        name: "",
        nameKaz: "",
        description: "",
        descriptionKaz: "",
        parent: "",
      },
      selectedOption: "",
    };
  },
  mounted() {
  },
  watch: {
    selectedOption(newValue) {
      const category = this.categories.find(i => i.id == newValue);
      this.creatingServiceItem.parent = category.id;
    }
  },
  computed: {
    ...mapGetters("servicesPage", ["categories"]),
    
    reqURL() {
      return  'https://admin.sunkarpremium.kz/api/servicesChild/createCategory.php'
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    changeOption(i) {
      this.selectedOption = i;
    },
    createServiceItem() {
      let formData = new FormData();
      let catValues = Object.entries(this.creatingServiceItem);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
        formData.append(catValues[i][0], catValues[i][1]);
      }

      if (emptyItems.length > 0) {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      } else {
        this.postContent(this.creatingServiceItem);
        formData = null;
        catValues = null;
        emptyItems = null;
      }
    },
    async postContent(item) {
      try {
        const response = await postItems(this.reqURL, item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });

          this.creatingServiceItem = {
            nameRu: "",
            nameEn: "",
            previewRu: "",
            previewEn: "",
            parent: "",
          }

          this.$emit('close');
          this.$emit('call');
        }
      } catch (e) {
          this.ACT_setMainNotification({
            type: 'failure',
            bool: 'true',
            message: e.response.data.message
          });
      } finally {}
    },
  },
}
</script>

<style scoped>
</style>
