<template>
  <div>
    <div class="modal__form-item form-item">
      <p class="form-item__label">
        Цены
        <b class="req-marker" v-if="required">*</b>
      </p>
      
      <div class="form-item__input ha">
        <b class="notif-marker">Чтобы добавить или редактировать необходим хотя бы один пункт 'Цены'</b>
        <new-input v-model="newSubItem.title" :label="'Название'" :required="true"></new-input>
        <new-input v-model="newSubItem.price" :label="'Цена'" :required="true"></new-input>

        <main-button class="mtmw" @click.native="addSubItem">Добавить</main-button>

        <div v-if="newSubItemArr.length > 0"  class="sub-items-list">
          <div class="sub-items-list__item" v-for="el in newSubItemArr" :key="el.id">
            {{ el.title }} : {{ el.price }}
            
            <div class="sub-items-list__edit-el" v-if="editElShow == el.id">
              <new-input v-model="el.title" :label="'Название'" :required="true"></new-input>
              <new-input v-model="el.price" :label="'Цена'" :required="true"></new-input>
            </div>

            <div class="dfsb" v-if="contElHide != el.id">
              <main-button class="mt" @click.native="editElShow = el.id, contElHide = el.id">Редактировать</main-button>
              <main-button class="mt" @click.native="removeSubItem(el.id)">Удалить</main-button>
            </div>

            <div class="dfsb" v-if="editElShow == el.id">
              <main-button class="mt" @click.native="editSubItem">Подтвердить</main-button>
              <main-button class="mt" @click.native="editElShow = -1, contElHide = -1">Отмена</main-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
  props: {
    subItems: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newSubItemArr: [],
      newSubItem: {
        title: "",
        price: "",
      },
      editElShow: -1,
      contElHide: -1,
    };
  },
  beforeMount() {
    if (this.subItems.length > 0) {
      this.newSubItemArr = this.subItems;
    }
  },
  watch: {
    newSubItemArr(newValue) {
      this.$emit('addSub', newValue);
    },
  },
  computed: {
  },
  methods: {
    addSubItem() {      
      if (!!this.newSubItem.title && !!this.newSubItem.price) {
        this.newSubItem.id = Date.now();
        this.newSubItemArr.push(this.newSubItem);
        this.newSubItem = {
          title: "",
          price: "",
        }
      } else {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      }
    },
    removeSubItem(id) {
      this.newSubItemArr = this.newSubItemArr.filter(el => el.id != id)
    },
    editSubItem() {
      this.$emit('addSub', this.newSubItemArr);
      this.contElHide = false;
      this.editElShow = -1;
    },
  }
};
</script>

<style scoped>
</style>