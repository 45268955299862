<template>
  <div>
    <button class="btn">
      <slot>
      
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'main-button'
};
</script>

<style scoped>
</style>
