<template>
  <div class="modal" @click="$emit('close')">
    <div class="modal__body modal__body_wide" @click.stop>
      <h5 class="modal__title">Добавить отзыв</h5>
      <button type="button" class="modal__close" @click="$emit('close')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>

      <form action="#" class="modal__form" @submit.prevent>
        <new-input v-model="name" :label="'Имя'" :required="true"></new-input>
        <new-input v-model="email" :label="'Дата'" :required="true"></new-input>
        <new-textarea v-model="comment" :label="'Отзыв'" :required="true"></new-textarea>

        <div class="dfsb">
          <main-button class="mt" @click.native="add()">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('close')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getItems } from "@/api/lists";

export default {
  data() {
    return {
      name: '',
      email: '',
      comment: '',
    }
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),
    ...mapActions("reviews", ["addReview", "getReviews"]),
    add() {
      const body = {
        name: this.name,
        email: this.email,
        comment: this.comment
      }
      this.addReview(body)
        .then(() => {
          this.$emit('close')
          this.clearFields()
        })
    },
    clearFields() {
      this.name = ''
      this.email = ''
      this.comment = ''
    }
  }
}
</script>