<template>
  <div>
    <div class="modal__form-item form-item">
      <label class="form-item__label">
        {{ label }}
        <b class="req-marker" v-if="required">*</b>
      </label>
      <input class="form-item__input" :type="type" :placeholder="label" :value="value" @input="$emit('input', $event.target.value)">
    </div>
  </div>
</template>

<script>
export default {
  name: "new-input",
  props: {
    value: [String, Number],
    type: {
      default: "text",
    },
    label: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
