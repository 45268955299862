<template>
  <div class="modal" @click="$emit('collapse')">
    <div class="modal__body modal__body_wide" @click.stop>
      <h5 class="modal__title">Редактировать страницу</h5>
      <button type="button" class="modal__close" @click="$emit('collapse')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>

      <form action="#" class="modal__form modal__form_high" @submit.prevent>
        <new-input v-model="changingPageItem.title" :label="'Название Ру'" :required="true"></new-input>
        <new-input v-model="changingPageItem.titleKaz" :label="'Название Каз'" :required="true"></new-input>
        <new-textarea v-model="changingPageItem.body" :label="'Описание Ру'" :required="true"></new-textarea>
        <new-textarea v-model="changingPageItem.bodyKaz" :label="'Описание Каз'" :required="true"></new-textarea>
       
        <div class="dfsb">
          <main-button class="mt" @click.native="editPageItem">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('collapse')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      changingPageItem: {},
    };
  },
  beforeMount() {
    this.changingPageItem = JSON.parse(JSON.stringify(this.item));
  },
  mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    editPageItem() {
      const formData = new FormData();
      const catValues = Object.entries(this.changingPageItem);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
        formData.append(catValues[i][0], catValues[i][1]);
      }

      if (emptyItems.length > 0) {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      } else {
        this.$emit('edit', this.changingPageItem);
      }
    },
  },
};
</script>

<style scoped>
</style>