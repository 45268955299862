<template>
  <div>
    <div class="modal__form-item form-item">
      <label class="form-item__label">
        {{ label }}
        <b class="req-marker" v-if="required">*</b>
      </label>
      <VueEditor
        class="form-item__textarea"
        :editor-toolbar="customToolbar"
        :placeholder="label"
        :value="value"
        @input="$emit('input', $event)"
      ></VueEditor>
      <!-- <textarea class="form-item__textarea" :placeholder="label" :value="value" @input="$emit('input', $event.target.value)"></textarea> -->
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "new-textarea",
  components: {
    VueEditor,
  },
  props: {
    value: [String, Number],
    label: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    customToolbar() {
      return [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["blockquote", "code-block"],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["link"],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ];
    },
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
