<template>
  <div class="modal" @click="$emit('close')">
    <div class="modal__body" @click.stop>
      <button type="button" class="modal__close" @click="$emit('close')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>
      <h5 class="modal__title">Удалить отзыв?</h5>
      <div>
        <p class="modal__notification">Данное действие безвозвратно удалит выбранный объект!</p>
        <div class="modal__notification-icon"><img src="@/assets/img/icons/alarms/notification-icon.svg" alt=""></img></div>
      </div>

      <div class="dfsb">
        <main-button class="mt" @click.native="deleteReview()">Подтвердить</main-button>
        <main-button class="mt" @click.native="$emit('close')">Отмена</main-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("reviews", ["delReview"]),
    deleteReview() {
      this.delReview()
        .then(() => {
          this.$emit('close')
        })
    }
  }
}
</script>

<style scoped>
</style>