<template>
  <div>
    <h4 class="current-page__subtitle">{{ activeSubmenuItem.titleRu }}</h4>
    <div class="services__filter">
      <search-input v-model="searchQuerry" :label="'Поиск...'"></search-input>
    </div>

    <main-button class="mt" @click.native="createPromoFormVisible = true">Добавить</main-button>
    <create-promo-item v-if="createPromoFormVisible" @close="createPromoFormVisible = false" @call="getContent()"/>
     
    <div class="current-page__doc-list current-page__category-list">
      <promo-list-item v-for="item, i in filteredContent" :key="i" :item="item" @call="getContent()"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getItems } from "@/api/lists";
import promoListItem from "@/components/newsPromoPage/promoItems/promoListItem.vue";
import createPromoItem from "@/components/newsPromoPage/promoItems/createPromoItem.vue";

export default {
  components: {
    promoListItem,
    createPromoItem
  },
  data() {
    return {
      content: [],
      createPromoFormVisible: false,
      searchQuerry: "",
    };
  },
  mounted() {
    this.getContent();
  },
  computed: {
    ...mapGetters(["activeSubmenuItem", "baseURL", "isLoading"]),

    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/news'
    },
    filteredContent() {
      return [... this.content].filter(i => {
        if (i.title.toLowerCase().includes(this.searchQuerry.toLowerCase())) {
          return i.title.toLowerCase().includes(this.searchQuerry.toLowerCase())
        } 
        return i.body.toLowerCase().includes(this.searchQuerry.toLowerCase())
      })
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    async getContent() {
      this.MUT_setIsLoading(true);
      
      try {
        const response = await getItems(this.reqURL + '/getNews.php', {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
          })
          if (response.status == 200) {
            this.content = response.data.results.filter(i => i.articleType !== '1');
          }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
  },
};
</script>

<style scoped>
</style>