<template>
  <div class="modal" @click="$emit('collapse')">
    <div class="modal__body modal__body_wide" @click.stop>
      <h5 class="modal__title">Редактировать прайслист</h5>
      <button type="button" class="modal__close" @click="$emit('collapse')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>

      <form action="#" class="modal__form modal__form_high" @submit.prevent>
        <new-input v-model="changingPriceItem.name" :label="'Позиция прайслиста Ру'" :required="true"></new-input>
        <new-input v-model="changingPriceItem.nameKaz" :label="'Позиция прайслиста Каз'" :required="true"></new-input>
        <new-input v-model="changingPriceItem.price" :label="'Цена'" :required="true"></new-input>
        <main-select :options="categories" :value="selectedOption" @change="changeOption" :required="false" :category="item.category"></main-select>

        <div class="dfsb">
          <main-button class="mt" @click.native="editPriceItem">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('collapse')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { postItems, } from "@/api/lists";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      changingPriceItem: {},
      selectedOption: "",
    };
  },
  beforeMount() {
    this.changingPriceItem = JSON.parse(JSON.stringify(this.item));
  },
  mounted() {
  },
  watch: {
    selectedOption(newValue) {
      const category = this.categories.find(i => i.id == newValue);
      this.changingPriceItem.category = category.id;
    }
  },
  computed: {
    ...mapGetters("pricesPage", ["categories"]),

    reqURL() {
      return  'https://admin.sunkarpremium.kz/api/prices/updateItem.php'
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    changeOption(i) {
      this.selectedOption = i;
    },
    editPriceItem() {
      let formData = new FormData();
      let catValues = Object.entries(this.changingPriceItem);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
        formData.append(catValues[i][0], catValues[i][1]);
      }

      if (emptyItems.length > 0) {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      } else {
        this.putContent(this.changingPriceItem);
        formData = null;
        catValues = null;
        emptyItems = null;
      }
    },
    async putContent(item) {
      try {
        const response = await postItems(this.reqURL, item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.ACT_setMainNotification({
            type: 'success',
            bool: true,
            message: response.data.message,
          });

          this.$emit('call');
          this.$emit('collapse');
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {}
    },
  },
};
</script>

<style scoped>
</style>