<template>
  <div class="">
    <h3 class="theraphy__title">Выберите категорию</h3>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  
  components: {
  },
  props: {

  },
  data() {
    return {

    };
  },
  mounted() {

  },
  watch: {

  },
  computed: {

  },
  mthods: {
    
  },
};
</script>

<style scoped>
</style>