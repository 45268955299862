import Vue from "vue";
import Vuex from "vuex";
import doctorsPage from "@/store/modules/doctorsPage.js"
import servicesPage from "@/store/modules/servicesPage.js"
import pricesPage from "@/store/modules/pricesPage.js"
import reviews from "@/store/modules/reviews"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseURL: 'https://admin.sunkarpremium.kz',
    isAuthorized: false,
    authorisationToken: "",
    menuItems: [
      {
        name: "info",
        titleRu: "Общая информация",
        "subItems": [],
      },
      {
        name: "add",
        titleRu: "Дополнительные страницы",
        "subItems": [],
      },
      {
        name: "services",
        titleRu: "Услуги",
        "subItems": [
          {
            name: "all_services",
            titleRu: "Все сервисы",
          },
          {
            name: "services_groups",
            titleRu: "Все категории",
          }
        ],
      },
      {
        name: "prices",
        titleRu: "Цены",
        "subItems": [
          {
            name: "all_prices",
            titleRu: "Все цены",
          },
        ],
      },
      {
        name: "doctors",
        titleRu: "Врачи",
        "subItems": [
          {
            name: "all_doctors",
            titleRu: "Все врачи",
          },
          {
            name: "specialities",
            titleRu: "Категории",
          }
        ],
      },
      {
        name: "newspromo",
        titleRu: "Новости и акции",
        "subItems": [
          {
            name: "all_news",
            titleRu: "Новости",
          },
          {
            name: "all_promo",
            titleRu: "Акции",
          }
        ],
      },
      {
        name: "reviews",
        titleRu: "Отзывы",
      }
    ],
    activeMenuItem: {},
    activeSubmenuItem: {},
    mainNotificationType: "",
    mainNotificationMessage: "",
    mainNotificationVisible: false,
    isLoading: false,
  },
  getters: {
    baseURL: (state) => state.baseURL,
    isAuthorized: (state) => state.isAuthorized,
    authorisationToken: (state) => state.authorisationToken,
    menuItems: (state) => state.menuItems,
    activeMenuItem: (state) => state.activeMenuItem,
    activeSubmenuItem: (state) => state.activeSubmenuItem,
    mainNotificationType: (state) => state.mainNotificationType,
    mainNotificationMessage: (state) => state.mainNotificationMessage,
    mainNotificationVisible: (state) => state.mainNotificationVisible,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    MUT_setIsAuthorized(state, bool) {
      state.isAuthorized = bool
    },
    MUT_setAuthorisationToken(state, string) {
      state.authorisationToken = string
    },
    MUT_setMenuItemsToState(state, arr) {
      state.menuItems = arr;
    },
    MUT_setActiveMenuItem(state, obj) {
      state.activeMenuItem = obj
    },
    MUT_setActiveSubmenuItem(state, obj) {
      state.activeSubmenuItem = obj
    },    
    MUT_setMainNotificationType(state, payload) {
      state.mainNotificationType = payload.type
    },
    MUT_setMainNotificationMessage(state, payload) {
      state.mainNotificationMessage = payload.message
    },
    MUT_setMainNotificationVisible(state, payload) {
      state.mainNotificationVisible = payload.bool
    },
    MUT_setIsLoading(state, bool) {
      state.isLoading = bool
    },
  },
  actions: {
    ACT_setMainNotification({commit}, payload) {
      commit("MUT_setMainNotificationType", payload);
      commit("MUT_setMainNotificationVisible", payload);
      commit("MUT_setMainNotificationMessage", payload);
      setTimeout(() => {
        commit("MUT_setMainNotificationType", {type: ''});
        commit("MUT_setMainNotificationVisible", {bool: false});
        commit("MUT_setMainNotificationMessage", {message: ''});
      }, 3000);
    },
  },
  modules: {
    servicesPage,
    pricesPage,
    doctorsPage,
    reviews
  },
});