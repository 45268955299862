export default {
  namespaced: true,
  state: {
    categories: [],
    attachments: [],
  },
  getters: {
    categories: (state) => state.categories,
    attachments: (state) => state.attachments,
  },
  mutations: {
    MUT_setCategories(state, arr) {
      state.categories = arr;
    },
    MUT_setAttachments(state, arr) {
      state.attachments = arr;
    },
  },
  actions: {},
}