<template>
  <div class="current-page__category-list-item category-list-item">
    <div class="category-list-item__label">
      <h6 class="category-list-item__title">{{ item.name }}</h6>
    </div>
    <div class="category-list-item__btn-group">
      <main-button class="category-list-item__btn" @click.native="activeEditServiceItem = item.id">Редактировать</main-button>
      <main-button class="category-list-item__btn" @click.native="activeRemoveServiceItem = item.id">Удалить</main-button>
    </div>
  
    <edit-service-item v-if="activeEditServiceItem === item.id" :item="item" @collapse="activeEditServiceItem = -1" @call="$emit('call')"></edit-service-item>
    <remove-service-item v-if="activeRemoveServiceItem === item.id" :item="item" @collapse="activeRemoveServiceItem = -1" @call="$emit('call')"></remove-service-item>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getCurrentItem} from "@/api/lists";
import editServiceItem from "@/components/servicesPage/items/editServiceItem.vue";
import removeServiceItem from "@/components/servicesPage/items/removeServiceItem.vue";

export default {
  components: {
    editServiceItem,
    removeServiceItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeEditServiceItem: "-1",
      activeRemoveServiceItem: "-1",
      currentItem: {},
    };
  },
  computed: {
    ...mapGetters(["baseURL", "mainNotificationVisible"]),
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    // getCurrentService(currentId) {
    //   getCurrentItem(this.baseURL + '/api/servicesChild/readOneCategory.php?id=', {
    //     params: {
    //       id: currentId,
    //     },
    //     headers: {
    //       // Authorization: "Bearer " + this.$store.getters.authorisationToken
    //       Authorization: "Bearer " + localStorage.getItem('authToken')
    //     },
    //   })
    //     .then(response => {
    //       if (response.status == 200) {
    //         this.currentItem = response.data;
    //         this.MUT_setIsLoading(false);
    //       }
    //     })
    //     .catch(e => {
    //       this.ACT_setMainNotification({
    //         type: 'failure',
    //         bool: 'true',
    //         message: e.response.data.message
    //       });
    //       this.MUT_setIsLoading(false);
    //     })
    // },
  }
};
</script>

<style scoped>
</style>