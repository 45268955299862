<template>
  <div>
    <h4 class="current-page__subtitle">{{ activeSubmenuItem.titleRu }}</h4>
    <div class="services__filter">
      <search-input v-model="searchQuerry" :label="'Поиск...'"></search-input>
    </div>

    <main-button class="mtmw" @click.native="createDocFormVisible = true">Добавить</main-button>
    <create-doc-item v-if="createDocFormVisible" @close="createDocFormVisible = false" @call="getContent()"/>
     
    <div class="current-page__doc-list current-page__category-list">
      <doc-list-item v-for="item, i in filteredContent" :key="i" :item="item" @call="getContent()" @edit="putContent" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getItems } from "@/api/lists";
import { getCategories } from "@/api/categories";
import docListItem from "@/components/doctorsPage/items/docListItem.vue";
import createDocItem from "@/components/doctorsPage/items/createDocItem.vue";

export default {
  components: {
    docListItem,
    createDocItem
  },
  data() {
    return {
      content: [],
      createDocFormVisible: false,
      searchQuerry: "",
    };
  },
  mounted() {
    this.getContent();
    this.getCategories();
    this.getAttachments();
  },
  computed: {
    ...mapGetters(["activeSubmenuItem", "baseURL", "isLoading"]),
    ...mapGetters("doctorsPage", ["categories", "attachments"]),
        
    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/doctors'
    },
    filteredContent() {
      return [... this.content].filter(i => {
        if (i.name.toLowerCase().includes(this.searchQuerry.toLowerCase())) {
          return i.name.toLowerCase().includes(this.searchQuerry.toLowerCase())
        }
        return i.category_name.toLowerCase().includes(this.searchQuerry.toLowerCase())
      })
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapMutations("doctorsPage", ["MUT_setCategories", "MUT_setAttachments",]),
    ...mapActions(["ACT_setMainNotification"]),
    
    async getContent() {
      this.MUT_setIsLoading(true);

      try {
        const response = await getItems(this.reqURL + '/getDoctors.php', {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.content = response.data.results;
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
    async getCategories() {
      this.MUT_setIsLoading(true);

      try {
        const response = await getCategories(this.reqURL + 'Categories/getCategories.php', {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.MUT_setCategories(response.data.results);
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
    async getAttachments() {
      this.MUT_setIsLoading(true);

      try {
        const response = await getCategories('https://admin.sunkarpremium.kz/api/services/getCategories.php', {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.MUT_setAttachments(response.data.results);
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
  },
};
</script>

<style scoped>
</style>