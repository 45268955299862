<template>
  <div class="modal_loader" v-if="show">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "main-loader",
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
  }
};
</script>

<style scoped>
</style>
