<template>
  <div class="current-page__category-list-item category-list-item">
    <div class="category-list-item__label">
      <h6 class="category-list-item__title">{{ item.title }}</h6>
    </div>
    <div class="category-list-item__btn-group">
      <main-button class="category-list-item__btn" @click.native="activeEditPageItem = item.id">Редактировать</main-button>
      <main-button class="category-list-item__btn" @click.native="activeRemovePageItem = item.id">Удалить</main-button>
    </div>
  
    <edit-page-item v-if="activeEditPageItem === item.id && !isLoading" :item="item" @collapse="activeEditPageItem = -1" @edit="toParentElUpdate"></edit-page-item>
    <remove-page-item v-if="activeRemovePageItem === item.id && !isLoading" :item="item" @collapse="activeRemovePageItem = -1" @remove="toParentElDelete"></remove-page-item>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getCurrentItem } from "@/api/lists";
import editPageItem from "@/components/addPage/editPageItem.vue";
import removePageItem from "@/components/addPage/removePageItem.vue";

export default {
  components: {
    editPageItem,
    removePageItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeEditPageItem: "-1",
      activeRemovePageItem: "-1",
      currentItem: {},
    };
  },
  computed: {
    ...mapGetters(["baseURL", "mainNotificationVisible", "isLoading"]),
  },
  methods: {
    ...mapMutations(["MUT_setMainNotificationVisible", "MUT_setMainNotification", "MUT_setIsLoading"]),

    toParentElUpdate(changedPageItem) {
      this.$emit('edit', changedPageItem);
      this.activeEditPageItem = "-1";
    },
    toParentElDelete(id) {
      this.$emit('remove', id);
      this.activeRemovePageItem = "-1";
    },
    // getCurrentPage(currentId) {
    //   this.MUT_setIsLoading(true);
      
    //   getCurrentItem('https://admin.sunkarpremium.kz/api' + '/pages/readOnepage.php?id=', {
    //     params: {
    //       id: currentId,
    //     },
    //     headers: {
    //       Authorization: "Bearer " + this.$store.getters.authorisationToken
    //     },
    //   })
    //     .then(response => {
    //       if (response.status == 200) {
    //         this.currentItem = response.data;
    //         this.currentItem.department = this.currentItem.department.split(',');
    //         this.MUT_setIsLoading(false);
    //       } else {
    //       }
    //     })
    //     .catch(e => {
    //       console.error(e);
    //       this.MUT_setMainNotification('failure');
    //       this.MUT_setMainNotificationVisible(true);
    //     })
    // },
  }
};
</script>

<style scoped>
</style>