<template>
  <div>
    <div class="modal__form-item form-item">
      <label class="form-item__label" v-if="label != 'labelless'">
        {{ label }}:
        <b class="req-marker" v-if="required">*</b>
      </label>
      <select class="form-item__input" @change="changeOptions">
        <option disabled selected value="">Выберите из списка</option>
        <option v-for="option in options" :selected="option.id == category" :key="option.id" :value="option.id" > {{ option.name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "main-select",
  props: {
    label: {
      type: String,
      default: "Категория"
    },
    category: {
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    changeOptions(event) {
      this.$emit('change', event.target.value);
    }
  },
};
</script>

<style scoped>
</style>