<template>
  <div class="modal" @click="$emit('collapse')">
    <div class="modal__body modal__body_wide" @click.stop>
      <h5 class="modal__title">Редактировать новость</h5>
      <button type="button" class="modal__close" @click="$emit('collapse')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>

      <form action="#" class="modal__form modal__form_high" @submit.prevent>
        <new-input v-model="changingNewsItem.title" :label="'Заголовок новости Ру'" :required="true"></new-input>
        <new-input v-model="changingNewsItem.titleKaz" :label="'Заголовок новости Каз'" :required="true"></new-input>
        <new-textarea v-model="changingNewsItem.body" :label="'Описание Ру'" :required="true"></new-textarea>
        <new-textarea v-model="changingNewsItem.bodyKaz" :label="'Описание Каз'" :required="true"></new-textarea>
        <new-file-input :label="'Фото для новости'" :required="false" @uploadFilesToParentEl="setFiles" :current="changingNewsItem.image" :currentDest="'news'"></new-file-input>

        <div class="dfsb">
          <main-button class="mt" @click.native="editNewsItem">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('collapse')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { postItems } from "@/api/lists";

export default {
  props: {
    item : {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileArr: [],
      changingNewsItem: {},
    };
  },
  mounted() {
    this.changingNewsItem = JSON.parse(JSON.stringify(this.item));
  },
  watch: {
  },
  computed: {
    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/news/updateArticle.php'
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    setFiles(arr) {
      this.fileArr = arr;
    },
    editNewsItem() {
      let formData = new FormData();

      if (this.fileArr.length !== 0) {
        delete this.changingNewsItem.image
        formData.append("image", this.fileArr[0]);
      }

      let catValues = Object.entries(this.changingNewsItem);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
        formData.append(catValues[i][0], catValues[i][1]);
      }

      if (emptyItems.length > 0) {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      } else {
        this.putContent(formData);
        for (let data of formData) {
        }
        formData = null;
        catValues = null;
        emptyItems = null;
      }
    },
    async putContent(item) {
      try {
        const response = await postItems(this.reqURL, item, {
          headers: {
              Authorization: "Bearer " + localStorage.getItem('authToken')
            }
        })
        if (response.status == 200) {
          this.ACT_setMainNotification({
            type: 'success',
            bool: true,
            message: response.data.message,
          });
          this.$emit('call');
          this.$emit('collapse');
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {}
    },
  },
};
</script>

<style scoped>
</style>