import axios from "axios";

async function getItems(URL, headers) {
  try {
    const response = await axios.get(URL, headers);
    return response;
  } catch (e) {
    throw new Error(e);
  }
}
async function deleteItems(URL, headers) {
  try {
    const response = await axios.delete(URL, headers);
    return response;
  } catch (e) {
    throw new Error(e);
  }
}
async function postItems(URL, data, headers) {
  try {
    const response = await axios.post(URL, data, headers);
    return response;
  } catch(e) {
    throw new Error(e);
  }
}
async function putItems(URL, data, headers) {
  try {
    const response = await axios.put(URL, data, headers);
    return response;
  } catch(e) {
    throw new Error(e);
  }
}
async function getCurrentItem(URL, headers) {
  try {
    const response = await axios.get(URL, headers);
    return response;
  } catch(e) {
    throw new Error(e);
  }
}

export {getItems, deleteItems, postItems, putItems, getCurrentItem}