<template>
  <div>
    <h4 class="current-page__subtitle">Отзывы</h4>
    <main-button class="mt" @click.native="showAdd = true" style="width: max-content;">Добавить</main-button>
    <div class="services__filter" v-if="reviews.length > 0">
      <search-input v-model="searchQuerry" :label="'Поиск...'"></search-input>
    </div>
    <div class="mt" v-if="reviews.length > 0">
      <div class="current-page__category-list-item category-list-item" v-for="(item, i) in filteredReviews" :key="i">
        <div class="category-list-item__label">
          <h6 class="category-list-item__title">{{ item.name }} / {{ item.email }}</h6>
        </div>
        <div class="category-list-item__btn-group">
          <main-button class="category-list-item__btn" @click.native="MUT_setCurrent(item); showEdit = true">Редактировать</main-button>
          <main-button class="category-list-item__btn" @click.native="MUT_setCurrent(item); showRemove = true">Удалить</main-button>
        </div>
      </div>
      <div v-if="filteredReviews.length < 1 && searchQuerry.length > 0">Ничего не найдено...</div>
    </div>
    <div class="mt" v-else>Отзывов пока что нет...</div>

    <addReview v-if="showAdd" @close="showAdd = false" />
    <removeReview v-if="showRemove" @close="showRemove = false"/>
    <editReview v-if="showEdit" @close="showEdit = false"/>
    
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getItems } from "@/api/lists";
import addReview from "@/components/reviews/addReview.vue";
import removeReview from "@/components/reviews/removeReview.vue";
import editReview from "@/components/reviews/editReview.vue";

export default {
  data() {
    return {
      searchQuerry: '',
      showEdit: false,
      showRemove: false,
      showAdd: false,
    }
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapMutations("reviews", ["MUT_setCurrent"]),
    ...mapActions("reviews", ["getReviews"]),
    ...mapActions(["ACT_setMainNotification"]),
  },
  computed: {
    ...mapGetters(["isLoading"]),
    ...mapGetters("reviews", ["reviews"]),
    filteredReviews() {
      return this.reviews.filter(item => {
        return item.name.toUpperCase().includes(this.searchQuerry.toUpperCase())
      })
    },
    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/reviews'
    },
  },
  mounted() {
    this.getReviews()
  },
  components: {
    addReview,
    removeReview,
    editReview
  }
}
</script>