import { render, staticRenderFns } from "./subPrice.vue?vue&type=template&id=5507d5ab&scoped=true"
import script from "./subPrice.vue?vue&type=script&lang=js"
export * from "./subPrice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5507d5ab",
  null
  
)

export default component.exports