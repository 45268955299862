<template>
  <div class="current-page__category-list-item category-list-item">
    <div class="category-list-item__label">
      <h6 class="category-list-item__title">{{ item.title }}</h6>
    </div>
    <div class="category-list-item__btn-group">
      <main-button class="category-list-item__btn" @click.native="activeEditNewsItem = item.id">Редактировать</main-button>
      <main-button class="category-list-item__btn" @click.native="activeRemoveNewsItem = item.id">Удалить</main-button>
    </div>
  
    <edit-news-item v-if="activeEditNewsItem === item.id" :item="item" @collapse="activeEditNewsItem = -1" @call="$emit('call')"></edit-news-item>
    <remove-news-item v-if="activeRemoveNewsItem === item.id" :item="item" @collapse="activeRemoveNewsItem = -1" @call="$emit('call')"></remove-news-item>
  </div>
</template>

<script>
import editNewsItem from "@/components/newsPromoPage/newsItems/editNewsItem.vue";
import removeNewsItem from "@/components/newsPromoPage/newsItems/removeNewsItem.vue";

export default {
  components: {
    editNewsItem,
    removeNewsItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      activeEditNewsItem: "-1",
      activeRemoveNewsItem: "-1",
    };
  },
  methods: {
  }
};
</script>

<style scoped>
</style>