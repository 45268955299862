<template>
  <div class="modal" @click="$emit('collapse')">
    <div class="modal__body" @click.stop>
      <button type="button" class="modal__close" @click="$emit('collapse')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>
      <h5 class="modal__title">Удалить профиль врача</h5>
      <div>
        <p class="modal__notification">Данное действие безвозвратно удалит выбранный объект!</p>
        <div class="modal__notification-icon"><img src="@/assets/img/icons/alarms/notification-icon.svg" alt=""></img></div>
      </div>

      <div class="dfsb">
        <main-button class="mt" @click.native="deleteContent(item.id)">Подтвердить</main-button>
        <main-button class="mt" @click.native="$emit('collapse')">Отмена</main-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions} from "vuex";
import { postItems } from "@/api/lists";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // deletingDocItem: {}
    };
  },
  mounted() {
    // this.deletingDocItem = JSON.parse(JSON.stringify(this.item));
  },
  computed: {
    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/doctors/deleteDoctor.php'
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    async deleteContent(id) {
      try {
        const response = await postItems(this.reqURL, {"id": id}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });
          this.$emit('call');
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.$emit('collapse');
      }
    },
  },
};
</script>

<style scoped>
</style>