<template>
  <div class="current-page__category-list-item category-list-item">
    <div class="category-list-item__label">
      <h6 class="category-list-item__title">{{ item.name }}</h6>
    </div>
    <div class="category-list-item__btn-group">
      <main-button class="category-list-item__btn" @click.native="activeEditPriceItem = item.id">Редактировать</main-button>
      <main-button class="category-list-item__btn" @click.native="activeRemovePriceItem = item.id">Удалить</main-button>
    </div>
  
    <edit-price-item v-if="activeEditPriceItem === item.id" :item="item" @collapse="activeEditPriceItem = -1" @call="$emit('call')"></edit-price-item>
    <remove-price-item v-if="activeRemovePriceItem === item.id" :item="item" @collapse="activeRemovePriceItem = -1" @call="$emit('call')"></remove-price-item>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getCurrentItem } from "@/api/lists";
import editPriceItem from "@/components/pricesPage/items/editPriceItem.vue";
import removePriceItem from "@/components/pricesPage/items/removePriceItem.vue";

export default {
  components: {
    editPriceItem,
    removePriceItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeEditPriceItem: "-1",
      activeRemovePriceItem: "-1",
      currentItem: {},
    };
  },
  computed: {
    ...mapGetters(["baseURL", "mainNotificationVisible"]),
  },
  methods: {
    ...mapMutations(["MUT_setMainNotificationVisible", "MUT_setMainNotification"]),
 
    // getCurrentDoc(currentId) {
    //   getCurrentItem('https://admin.sunkarpremium.kz/api' + '/doctors/readOneDoctor.php?id=', {
    //     params: {
    //       id: currentId,
    //     },
    //     headers: {
    //       Authorization: "Bearer " + this.$store.getters.authorisationToken
    //     },
    //   })
    //     .then(response => {
    //       if (response.status == 200) {
    //         this.currentItem = response.data;
    //       } else {
    //         console.log(response.data.message);
    //       }
    //     })
    //     .catch(e => {
    //       console.error(e);
    //       this.MUT_setMainNotification('failure');
    //       this.MUT_setMainNotificationVisible(true);
    //     })
    // },
  }
};
</script>

<style scoped>
</style>