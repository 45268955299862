<template>
  <div class="current-page__category-list-item category-list-item">
    <div class="category-list-item__label">
      <h6 class="category-list-item__title">{{ item.name }} / {{ item.category_name }}</h6>
    </div>
    <div class="category-list-item__btn-group">
      <main-button class="category-list-item__btn" @click.native="activeEditDocItem = item.id, getCurrentDoc(item.id)">Редактировать</main-button>
      <main-button class="category-list-item__btn" @click.native="activeRemoveDocItem = item.id">Удалить</main-button>
    </div>
  
    <edit-doc-item v-if="activeEditDocItem === item.id && !isLoading" :item="currentItem" @collapse="activeEditDocItem = -1" @call="$emit('call')"></edit-doc-item>
    <remove-doc-item v-if="activeRemoveDocItem === item.id && !isLoading" :item="item" @collapse="activeRemoveDocItem = -1" @call="$emit('call')"></remove-doc-item>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getCurrentItem} from "@/api/lists";
import editDocItem from "@/components/doctorsPage/items/editDocItem.vue";
import removeDocItem from "@/components/doctorsPage/items/removeDocItem.vue";

export default {
  components: {
    editDocItem,
    removeDocItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeEditDocItem: "-1",
      activeRemoveDocItem: "-1",
      currentItem: {},
    };
  },
  computed: {
    ...mapGetters(["baseURL", "isLoading"]),
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    async getCurrentDoc(currentId) {
      this.MUT_setIsLoading(true);
      
      try {
        const response = await getCurrentItem(this.baseURL + '/api/doctors/readOneDoctor.php?id=', {
          params: {
            id: currentId,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          },
        })
        if (response.status == 200) {
          this.currentItem = response.data;
          this.currentItem.department = this.currentItem.department.split(',');
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
  }
};
</script>

<style scoped>
</style>