<template>
  <div class="modal" @click="$emit('collapse')">
    <div class="modal__body" @click.stop>
      <button type="button" class="modal__close" @click="$emit('collapse')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>
      <h5 class="modal__title">Удалить страницу</h5>
      <div>
        <p class="modal__notification">Данное действие безвозвратно удалит выбранный объект!</p>
        <div class="modal__notification-icon"><img src="@/assets/img/icons/alarms/notification-icon.svg" alt=""></img></div>
      </div>

      <div class="dfsb">
        <main-button class="mt" @click.native="removePageItem">Подтвердить</main-button>
        <main-button class="mt" @click.native="$emit('collapse')">Отмена</main-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deletingPageItem: {}
    };
  },
  mounted() {
    this.deletingPageItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    removePageItem() {
      this.$emit('remove', this.deletingPageItem.id);
    },
  },
};
</script>

<style scoped>
</style>