<template>
 <div class="modal" @click="$emit('collapse')">
    <div class="modal__body modal__body_wide" @click.stop>
      <h5 class="modal__title">Редактировать категорию</h5>
      <button type="button" class="modal__close" @click="$emit('collapse')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>

      <form action="#" class="modal__form modal__form_high" @submit.prevent>
        <new-input v-model="changingCategory.name" :label="'Категория Ру'" :required="true"></new-input>
        <new-input v-model="changingCategory.nameKaz" :label="'Категория Каз'" :required="true"></new-input>
        <new-textarea v-model="changingCategory.description" :label="'Описание Ру'" :required="true"></new-textarea>
        <new-textarea v-model="changingCategory.descriptionKaz" :label="'Описание Каз'" :required="true"></new-textarea>

        <div class="dfsb">
          <main-button class="mt" @click.native="editCategory">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('collapse')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { postCategories } from "@/api/categories";

export default {
  props: {
    item : {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      changingCategory: {},
    };
  },
  mounted() {
    this.changingCategory = JSON.parse(JSON.stringify(this.item));
  },
  computed: {
    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/services/updateCategory.php'
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    editCategory() {
      let catValues = Object.entries(this.changingCategory);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
      }
      if (emptyItems.length > 0) {
        alert("Присутствуют пустые поля");
      } else {
        this.putContent(this.changingCategory);
        catValues = null;
        emptyItems = null;
      }
    },
    async putContent(changedCategory) {
      try {
        const response = await postCategories(this.reqURL, changedCategory, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        });
        if (response.status == 200) {
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });

          this.$emit('call');
          this.$emit('collapse');
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {}
    },
  },
};
</script>

<style scoped>
</style>
