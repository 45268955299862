<template>
  <div class="app body">
    <mainHeader @login="modalAuthorisationVisible = true" @logout="logOut()"></mainHeader>
        
    <div class="services__body" v-if="isAuthorized" >
      <main-side-menu :menuItems="menuItems"/>
      <router-view class="view-body"></router-view>
    </div>

    <main-modal :show="modalAuthorisationVisible" @hide="modalAuthorisationVisible = false">
      <div>
        <h5 class="theraphy__title tc">Авторизация</h5>
        <new-input v-model="user.login" :label="'Login'"></new-input>
        <new-input v-model="user.password" :label="'Password'" :type="'password'"></new-input>
        <main-button class="mt" @click.native="logIn">Вход</main-button>
      </div>
    </main-modal>
    
    <main-notification v-if="mainNotificationVisible"></main-notification>
    <main-loader :show="isLoading"></main-loader>
    <footer></footer>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations, mapActions } from "vuex";
import mainSideMenu from "@/components/mainSideMenu/mainSideMenu.vue";
import mainHeader from "@/components/mainHeader/mainHeader.vue";

export default {
  components: {
    mainSideMenu,
    mainHeader,
  },
  data() {
    return {
      content: [],
      user: {
        login: "",
        password: ""
      },
      modalAuthorisationVisible: false,
    };
  },
  beforeMount() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["baseURL", "isAuthorized", "authorisationToken", "menuItems", "mainNotificationVisible", "isLoading"]),
  },
  methods: {
    ...mapMutations(["MUT_setAuthorisationToken", "MUT_setIsAuthorized", "MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    logOut() {
      this.MUT_setIsAuthorized(false);
      this.MUT_setAuthorisationToken('');
      localStorage.clear()
    },
    async logIn() {
      this.MUT_setIsLoading(true);

      try {
        const response = await axios.post(this.baseURL + '/api/login.php', this.user);
        if (response.status == 200) {
          this.MUT_setIsAuthorized(true);
          this.MUT_setAuthorisationToken(response.data.jwt);
          localStorage.setItem('isAuth', true);
          localStorage.setItem('authToken', response.data.jwt);

          this.ACT_setMainNotification({
            type: 'success',
            bool: true,
            message: response.data.message,
          });
        } else {
        }
      } catch (e) {
          this.ACT_setMainNotification({
            type: 'failure',
            bool: 'true',
            message: e.response.data.message
          });
      } finally {
        this.modalAuthorisationVisible = false;
        this.MUT_setIsLoading(false);
        this.user = {
          login: "",
          password: ""
        }
      }
    },
  },
};
</script>

<style scoped>
</style>