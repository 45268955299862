<template>
  <div>
    <h3 class="current-page__title">{{ activeMenuItem.titleRu }}</h3>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["activeMenuItem"]),
  },
  
};
</script>

<style scoped>
</style>