<template>
  <div class="modal" @click="ACT_setMainNotification({type: '', bool: false, message: ''})">
    <div class="modal__body" @click.stop>
      <button type="button" class="modal__close" @click="ACT_setMainNotification({type: '', bool: false, message: ''})">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>
      <h5 class="modal__title" v-if="mainNotificationType =='success'">Сохранено</h5>
      <h5 class="modal__title" v-if="mainNotificationType =='failure'">Не удалось</h5>
      <div>
        <div class="modal__notification-icon" v-if="mainNotificationType =='success'">
          <img src="@/assets/img/icons/alarms/success-icon.svg"></img>
        </div>
        <div class="modal__notification-icon" v-if="mainNotificationType =='failure'">
          <img src="@/assets/img/icons/alarms/failrule-icon.svg"></img>
        </div>
        
        <h5 class="modal__title">{{ mainNotificationMessage }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
  name: "main-notification",
  props: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    ...mapGetters(["mainNotificationType", "mainNotificationVisible", "mainNotificationMessage"]),
  },
  methods: {
    ...mapMutations(["MUT_setMainNotificationVisible"]),
    ...mapActions(["ACT_setMainNotification"]),
  },
};
</script>

<style scoped>
</style>