<template>
  <div class="current-page__category-list-item category-list-item">
    <div class="category-list-item__label">
      <h6 class="category-list-item__title">{{ item.name }}</h6>
    </div>
    <div class="category-list-item__btn-group">
      <main-button class="category-list-item__btn" @click.native="activeEditCatItem = item.id">Редактировать</main-button>
      <main-button class="category-list-item__btn" @click.native="activeRemoveCatItem = item.id">Удалить</main-button>
    </div>
  
    <edit-cat-item v-if="activeEditCatItem === item.id" :item="item" @collapse="activeEditCatItem = -1" @call="$emit('call')"></edit-cat-item>
    <remove-cat-item v-if="activeRemoveCatItem === item.id" :item="item" @collapse="activeRemoveCatItem = -1" @call="$emit('call')"></remove-cat-item>
  </div>
</template>

<script>
import editCatItem from "@/components/doctorsPage/categories/editCatItem.vue";
import removeCatItem from "@/components/doctorsPage/categories/removeCatItem.vue";

export default {
  components: {
    editCatItem,
    removeCatItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      activeEditCatItem: "-1",
      activeRemoveCatItem: "-1",
      confirmModalVisible: false,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
</style>