<template>
  <div class="modal-static">
    <div>
      <h5 class="modal__title mt0">Добавить страницу</h5>

      <form action="#" class="modal__form" @submit.prevent>
        <new-input v-model="creatingPageItem.title" :label="'Название Ру'" :required="true"></new-input>
        <new-input v-model="creatingPageItem.titleKaz" :label="'Название Каз'" :required="true"></new-input>
        <new-textarea v-model="creatingPageItem.body" :label="'Описание Ру'" :required="true"></new-textarea>
        <new-textarea v-model="creatingPageItem.bodyKaz" :label="'Описание Каз'" :required="true"></new-textarea>

        <div class="dfsb">
          <main-button class="mtmw" @click.native="createPageItem">Подтвердить</main-button>
          <main-button class="mtmw" @click.native="$emit('close')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      creatingPageItem: {
        title: "",
        titleKaz: "",
        body: "",
        bodyKaz: ""
      },
    };
  },
  mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    createPageItem() {
      const formData = new FormData();
      const catValues = Object.entries(this.creatingPageItem);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
        formData.append(catValues[i][0], catValues[i][1]);
      }

      if (emptyItems.length > 0) {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      } else {
        this.$emit('create', this.creatingPageItem);
        this.creatingPageItem = {
          title: "",
          titleKaz: "",
          body: "",
          bodyKaz: "",
        }
      }
    }
  },
}
</script>

<style scoped>
</style>
