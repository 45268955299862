<template>
  <div>
    <div class="modal__form-item form-item">
      <label class="form-item__label">
        {{ label }}<b class="req-marker" v-if="required"> *</b>:
      </label>
      <template v-if="hideInput">
        <div class="row">
          <img :src="'https://admin.sunkarpremium.kz/images/' + currentDest + '/' + current" width="32px">
          <button @click="hideInput = false" class="btn">Изменить</button>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <input class="form-item__input" type="file" accept="image/jpeg, image/png" :placeholder="label" @change="saveFile($event)" >
          <button v-if="current" @click="hideInput = true" class="btn">Отмена</button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "new-file-input",
  props: {
    label: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    current: {
      type: String,
      default: "",
    },
    currentDest: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      curImageLink: "",
      hideInput: false,
    };
  },
  mounted() {
    this.hideInput = !!this.current
  },
  methods: {
    saveFile(e) {
      this.$emit('uploadFilesToParentEl', e.target.files);
    }
  },
};
</script>

<style scoped>
.row {
  display: flex;
  gap: 20px;
  align-items: center;
}
</style>