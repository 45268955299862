<template>
  <div>
    <header class="header">
    <div class="header__info">
        <div class="header__info-row">
          <div class="header__info-column header__info-column_1">
            <div class="header__links-lang-wrapper">
              <div class="header__language-menu">
                <div class="auth-status" v-if="!isAuthorized">
                  <h4 class="auth-status__title">Для продолжения необходим</h4>
                  <button class="btn auth-status__btn" @click="$emit('login',)">Вход</button>
                </div>
                <div class="auth-status" v-if="isAuthorized">
                  <h4 class="auth-status__title">Пользователь авторизован:</h4>
                  <button class="btn auth-status__btn" @click="$emit('logout')">Выход</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </header>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
export default {
  created() {
    if (localStorage.getItem('isAuth')) {
      this.MUT_setIsAuthorized(localStorage.getItem('isAuth'));
      this.MUT_setAuthorisationToken(localStorage.getItem('authToken'));
    }
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
  },
  methods: {
    ...mapMutations(["MUT_setIsAuthorized", "MUT_setAuthorisationToken"]),
  },
};
</script>

<style scoped>
</style>