<template>
  <div class="services__search-wrapper">
    <div class="services__search search">
      <input class="search__input" :type="type" :placeholder="label" :value="value" @input="$emit('input', $event.target.value)">
      <button class="search__btn"><img src="@/assets/img/icons/regular/loupe-icon.svg" alt=""></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "search-input",
  props: {
    value: [String, Number],
    type: {
      default: "text",
    },
    label: {
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
