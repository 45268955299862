<template>
  <div class="modal-static">
    <div>
      <h5 class="modal__title mt0">Добавить категорию</h5>

      <form action="#" class="modal__form" @submit.prevent>
        <new-input v-model="creatingCategory.name" :label="'Категория Ру'" :required="true"></new-input>
        <new-input v-model="creatingCategory.nameKaz" :label="'Категория Каз'" :required="true"></new-input>
        <new-textarea v-model="creatingCategory.description" :label="'Описание Ру'" :required="true"></new-textarea>
        <new-textarea v-model="creatingCategory.descriptionKaz" :label="'Описание Каз'" :required="true"></new-textarea>

        <div class="dfsb">
          <main-button class="mt" @click.native="createCategory">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('close')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { postCategories } from "@/api/categories";

export default {
  data() {
    return {
      creatingCategory: {
        name: "",
        nameKaz: "",
        description: "",
        descriptionKaz: "",
      },
    };
  },
  mounted() {
  },
  computed: {
    reqURL() {
      return 'https://admin.sunkarpremium.kz/api/services/createCategory.php'
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    createCategory() {
      let catValues = Object.entries(this.creatingCategory);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
      }
      if (emptyItems.length > 0) {
        alert("Присутствуют пустые поля");
      } else {      
        this.postContent(this.creatingCategory);
        catValues = null;
        emptyItems = null;
      }
    },
    
    async postContent(createdCategory) {
      try {
        const response = await postCategories(this.reqURL, createdCategory, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        });
        if (response.status == 200) {
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });

          this.creatingCategory = {
            name: "",
            nameKaz: "",
            description: "",
            descriptionKaz: "",
          }

          this.$emit('call');
          this.$emit('close');
        }
      } catch(e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {}
    },
  },
}
</script>

<style scoped>
</style>
