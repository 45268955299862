import mainButton from "@/components/UI Kit/mainButton.vue";
import mainModal from "@/components/UI Kit/mainModal.vue";
import mainUpward from "@/components/UI Kit/mainUpward.vue";
import mainDownward from "@/components/UI Kit/mainDownward.vue";
import newInput from "@/components/UI Kit/newInput.vue";
import searchInput from "@/components/UI Kit/searchInput.vue";
import newFileInput from "@/components/UI Kit/newFileInput.vue";
import newTextarea from "@/components/UI Kit/newTextarea.vue";
import mainSelect from "@/components/UI Kit/mainSelect.vue";
import mainCheckboxList from "@/components/UI Kit/mainCheckboxList.vue";
import mainNotification from "@/components/UI Kit/mainNotification.vue";
import mainLoader from "@/components/UI Kit/mainLoader.vue";

export default [
  mainButton,
  mainModal,
  mainDownward,
  mainUpward,
  newInput,
  searchInput,
  newFileInput,
  newTextarea,
  mainSelect,
  mainCheckboxList,
  mainNotification,
  mainLoader,
]