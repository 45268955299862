<template>
  <div class="modal-static">
    <div>
      <h5 class="modal__title mt0">Добавить врача</h5>
      
      <form action="#" class="modal__form" @submit.prevent>
        <new-input v-model="creatingDocItem.name" :label="'Имя врача'" :required="true"></new-input>
        <new-textarea v-model="creatingDocItem.description" :label="'Описание Ру'" :required="true"></new-textarea>
        <new-textarea v-model="creatingDocItem.descriptionKaz" :label="'Описание Каз'" :required="true"></new-textarea>
        <new-input v-model="creatingDocItem.experience" :label="'Год начала практики'" :required="true"></new-input>

        <sub-price :required="true" @addSub="addSubPrice"></sub-price>
        <sub-practice :required="true" @addSub="addSubPractice"></sub-practice>
        <sub-schedule :required="true" @addSub="addSubSchedule"></sub-schedule>

        <main-select :options="categories" :value="selectedOption" @change="changeOption" :required="true"></main-select>
        <new-file-input :label="'Фото врача'" :required="true" @uploadFilesToParentEl="setFiles"></new-file-input>
        <main-checkbox-list :checkItems="attachments" :required="true" @add="attachCheckedItems"></main-checkbox-list>

        <div class="dfsb">
          <main-button class="mt" @click.native="createDocItem">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('close')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from "vuex";
import { postItems } from "@/api/lists";
import subPrice from "@/components/doctorsPage/items/subPrice.vue";
import subPractice from "@/components/doctorsPage/items/subPractice.vue";
import subSchedule from "@/components/doctorsPage/items/subSchedule.vue";

export default {
  components: {
    subPrice,
    subPractice,
    subSchedule,
  },
  data() {
    return {
      fileArr: [],
      selectedAttachments: [],
      creatingDocItem: {
        name: "",
        category: "",
        department: [],
        appointmentPrice: [],
        educationQualifications: [],
        schedule: [],
        experience: "",
        description: "",
        descriptionKaz: "",
      },
      selectedOption: "",
    };
  },
  mounted() {
  },
  watch: {
    selectedOption(newValue) {
      const category = this.categories.find(i => i.id == newValue);
      this.creatingDocItem.category = category.id;
    }
  },
  computed: {
    ...mapGetters("doctorsPage", ["categories", "attachments"]),

    reqURL() {
      return  'https://admin.sunkarpremium.kz/api/doctors/createDoctor.php'
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    setFiles(arr) {
      this.fileArr = arr;
    },
    changeOption(i) {
      this.selectedOption = i;
    },
    attachCheckedItems(arr) {
      this.creatingDocItem.department = arr;
    },
    addSubPrice(arr) {
      this.creatingDocItem.appointmentPrice = JSON.stringify(arr);
    },
    addSubPractice(arr) {
      this.creatingDocItem.educationQualifications = JSON.stringify(arr);
    },
    addSubSchedule(arr) {
      this.creatingDocItem.schedule = JSON.stringify(arr);
    },

    createDocItem() {
      this.creatingDocItem.department = this.creatingDocItem.department.filter(item => item.length > 0)
      let formData = new FormData();
      let catValues = Object.entries(this.creatingDocItem);

      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
        formData.append(catValues[i][0], catValues[i][1]);
      }

      if (emptyItems.length > 0
        || !this.fileArr[0]
        || this.creatingDocItem.department.length == 0
        || this.creatingDocItem.appointmentPrice.length < 3
        || this.creatingDocItem.educationQualifications.length < 3
        || this.creatingDocItem.schedule.length < 3
      ) {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      } else {
        formData.append("image", this.fileArr[0]);
        this.postContent(formData);
        formData = null;
        catValues = null;
        emptyItems = null;
      }
    },
    async postContent(item) {
      try {
        const response = await postItems(this.reqURL, item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken'),
            "Content-Type": "multipart/form-data",
          }
        })
        if (response.status == 200) {
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });

          this.creatingDocItem = {
            name: "",
            category: "",
            department: [],
            appointmentPrice: [],
            educationQualifications: [],
            schedule: [],
            experience: "",
            description: "",
            descriptionKaz: "",
          };
          this.fileArr = [];
          
          this.$emit('close');
          this.$emit('call');
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {}
    },
  },
}
</script>

<style scoped>
</style>
