<template>
  <div>
    <div class="modal__form-item form-item">
      <p class="form-item__label">
        Отделения
        <b class="req-marker" v-if="required">*</b>
      </p>
      
      <div class="form-item__input ha">
        <b class="notif-marker">Отметьте отделения, к которым будет привязан врач.</b>
        <div>
          <div style=" margin-top: 8px;" v-for="checkItem in checkItems" :key="checkItem.id">
            <input type="checkbox" :id="checkItem.name" :value="checkItem.id" :checked="checkItem.checked" @change="collectCheckItems"/>
            <label :for="checkItem.name">{{ checkItem.name }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "main-checkbox-list",
  props: {
    checkItems: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attachedItems: [],
      dettachedItems: [],
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    collectCheckItems(e) {
      if (e.target.checked) {
        this.attachedItems.push(e.target.value);
        this.dettachedItems = this.dettachedItems.filter(i => i !== e.target.value);
      } else {
        this.attachedItems = this.attachedItems.filter(i => i !== e.target.value);
        this.dettachedItems.push(e.target.value);
      }
      this.$emit('add', this.attachedItems);
      this.$emit('cut', this.dettachedItems);
    },
  }
};
</script>

<style scoped>
</style>