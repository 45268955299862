<template>
  <div class="modal" @click="$emit('collapse')">
    <div class="modal__body modal__body_wide" @click.stop>
      <button type="button" class="modal__close" @click="$emit('collapse')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>
      
      <form action="#" class="modal__form modal__form_high" @submit.prevent>
        <h5 class="modal__title" style="margin-top: 0px;">Редактировать информацию</h5>
        <new-input v-model="changingInfoItem.headerText" :label="'Заголовок шапки Ру'" :required="true"></new-input>
        <new-input v-model="changingInfoItem.headerTextKaz" :label="'Заголовок шапки Каз'" :required="true"></new-input>
        <new-input v-model="changingInfoItem.phone" :label="'Номер телефона'" :required="true"></new-input>
        <new-input v-model="changingInfoItem.email" :label="'Электронная почта'" :required="true"></new-input>
        <new-input v-model="changingInfoItem.address" :label="'Адрес'" :required="true"></new-input>
        <new-input v-model="changingInfoItem.tikTok" :label="'Ссылка на TikTok'" :required="true"></new-input>
        <new-input v-model="changingInfoItem.instagram" :label="'Ссылка на Instagram'" :required="true"></new-input>

        <div class="dfsb">
          <main-button class="mt" @click.native="editInfoItem">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('collapse')">Отмена</main-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      changingInfoItem: {},
    };
  },
  beforeMount() {
    this.changingInfoItem = JSON.parse(JSON.stringify(this.item));
  },
  mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    editInfoItem() {
      const formData = new FormData();
      const catValues = Object.entries(this.changingInfoItem);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
        formData.append(catValues[i][0], catValues[i][1]);
      }

      if (emptyItems.length > 0) {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      } else {
        // this.$emit('edit', formData);
        this.$emit('edit', this.changingInfoItem);

        // console.log(this.changingInfoItem)
        // for (let data of formData) {
        //   console.log(data)
        // }
      }
    },
  },
};
</script>

<style scoped>
</style>