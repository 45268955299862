import axios from "axios";

async function getCategories(URL, headers) {
  try {
    const response = await axios.get(URL, headers);
    return response;
  } catch (e) {
    throw new Error(e);
  }
}
async function deleteCategories(URL, headers) {
  try {
    const response = await axios.delete(URL, headers);
    return response;
  } catch (e) {
    throw new Error(e);
  }
}
async function postCategories(URL, data, headers) {
  try {
    const response = await axios.post(URL, data, headers);
    return response;
  } catch(e) {
    throw new Error(e);
  }
}
async function putCategories(URL, data, headers) {
  try {
    const response = await axios.put(URL, data, headers);
    return response;
  } catch(e) {
    throw new Error(e);
  }
}

export {getCategories, deleteCategories, postCategories, putCategories}