import { getItems, postItems } from "@/api/lists";
const reqURL = 'https://admin.sunkarpremium.kz/api/reviews'

export default {
  namespaced: true,
  state: {
    reviews: [],
    current: {},
  },
  getters: {
    reviews: (state) => state.reviews,
    current: (state) => state.current,
  },
  mutations: {
    MUT_setReviews(state, arr) {
      state.reviews = arr;
    },
    MUT_setCurrent(state, arr) {
      state.current = arr;
    },
  },
  actions: {
    getReviews({ commit, dispatch }) {
      commit('MUT_setIsLoading', true, { root: true })
      getItems(reqURL + '/getItems.php', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('authToken')
        }
      })
        .then(res => {
          if (res.status == 200) {
            commit('MUT_setReviews', res.data.results)
            commit('MUT_setIsLoading', false, { root: true })
          } else {
            dispatch('ACT_setMainNotification', {
              type: 'failure',
              bool: 'true',
              message: res.data.message
            }, { root: true })
          }
        })
        .finally(() => {
          commit('MUT_setIsLoading', false, { root: true })
        })
    },
    addReview({ commit, dispatch }, obj) {
      commit('MUT_setIsLoading', true, { root: true })
      postItems(reqURL + '/createItem.php', obj, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('authToken')
        }
      })
        .then(res => {
          if (res.status == 200) {
            dispatch('ACT_setMainNotification', {
              type: 'success',
              bool: true,
              message: res.data.message
            }, { root: true })
            commit('MUT_setIsLoading', false, { root: true })
          } else {
            dispatch('ACT_setMainNotification', {
              type: 'failure',
              bool: 'true',
              message: res.data.message
            }, { root: true })
          }
        })
        .finally(() => {
          commit('MUT_setIsLoading', false, { root: true })
          dispatch('getReviews')
        })
    },
    editReview({ commit, dispatch }, obj) {
      commit('MUT_setIsLoading', true, { root: true })
      postItems(reqURL + '/updateItem.php', obj, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('authToken')
        }
      })
        .then(res => {
          if (res.status == 200) {
            dispatch('ACT_setMainNotification', {
              type: 'success',
              bool: true,
              message: res.data.message
            }, { root: true })
            commit('MUT_setIsLoading', false, { root: true })
          } else {
            dispatch('ACT_setMainNotification', {
              type: 'failure',
              bool: 'true',
              message: res.data.message
            }, { root: true })
          }
        })
        .finally(() => {
          commit('MUT_setIsLoading', false, { root: true })
          dispatch('getReviews')
        })
    },
    delReview({ commit, dispatch, getters }) {
      commit('MUT_setIsLoading', true, { root: true })
      postItems(reqURL + '/deleteItem.php', getters.current, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('authToken')
        }
      })
        .then(res => {
          if (res.status == 200) {
            commit('MUT_setCurrent', {})
            dispatch('ACT_setMainNotification', {
              type: 'success',
              bool: true,
              message: res.data.message
            }, { root: true })
            commit('MUT_setIsLoading', false, { root: true })
          } else {
            dispatch('ACT_setMainNotification', {
              type: 'failure',
              bool: 'true',
              message: res.data.message
            }, { root: true })
          }
        })
        .finally(() => {
          commit('MUT_setIsLoading', false, { root: true })
          dispatch('getReviews')
        })
    },
  },
}