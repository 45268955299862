<template>
  <div>
    <h3 class="current-page__title">{{ activeMenuItem.titleRu }}</h3>
    <div class="current-page__category-list-item category-list-item db">
      <div class="category-list-item__label category-list-item__label_info">
        <h6 class="category-list-item__title"><b>Заголовок шапки:</b> {{ infoItems.headerText }}/{{ infoItems.headerTextKaz }}</h6>
        <h6 class="category-list-item__title"><b>Телефон:</b> {{ infoItems.phone }}</h6>
        <h6 class="category-list-item__title"><b>Электронная почта:</b> {{ infoItems.email }}</h6>
        <h6 class="category-list-item__title"><b>Адрес:</b> {{ infoItems.address }}</h6>
        <h6 class="category-list-item__title"><b>Tiktok:</b> {{ infoItems.tikTok }}</h6>
        <h6 class="category-list-item__title"><b>Inst:</b> {{ infoItems.instagram }}</h6>
      </div>
      <div class="category-list-item__btn-group">
        <main-button class="category-list-item__btn" @click.native="activeEditInfoItem = true">Редактировать</main-button>
      </div>

      <edit-info-item v-if="activeEditInfoItem && !isLoading" :item="infoItems" @collapse="activeEditInfoItem = false" @edit="updateInfoItem"></edit-info-item>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations, mapActions } from "vuex";
import editInfoItem from "@/components/infoPage/editInfoItem.vue";

export default {
  components: {
    editInfoItem,
  },
  data() {
    return {
      infoItems: {},
      activeEditInfoItem: false,
      reqURL: 'https://admin.sunkarpremium.kz/api/settings',
    };
  },
  mounted() {
    this.getInfo();
  },
  computed: {
    ...mapGetters(["baseURL", "activeMenuItem", "isLoading"]),
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    async getInfo() {
      try {
        this.MUT_setIsLoading(true);
        const response = await axios.get(this.reqURL + '/getSettings.php', {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        });
        if (response.status == 200) {
          this.infoItems = response.data;
        } 
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.MUT_setIsLoading(false);
      }
    },
    async updateInfoItem(item) {
      this.MUT_setIsLoading(true);

      try {
        const response = await axios.post(this.reqURL + '/updateSettings.php', item, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        });
        if (response.status == 200) {
          this.getInfo();
          this.ACT_setMainNotification({
            type: 'success', 
            bool: true, 
            message: response.data.message
          });
        } 
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {
        this.activeEditInfoItem = false;
        this.MUT_setIsLoading(false);
      }
    },
  }
};
</script>

<style scoped>
</style>