import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import styles from './assets/css/styles.scss'
import components from "@/components/UI Kit"

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

components.forEach(comp => {
  Vue.component(comp.name, comp);
})

app.$mount('#app')

Vue.config.productionTip = false





