<template>
  <div class="modal-static">
    <div>
      <h5 class="modal__title mt0">Добавить новость</h5>

      <form action="#" class="modal__form" @submit.prevent>
        <new-input v-model="creatingNewsItem.title" :label="'Заголовок новости Ру'" :required="true"></new-input>
        <new-input v-model="creatingNewsItem.titleKaz" :label="'Заголовок новости Каз'" :required="true"></new-input>
        <new-textarea v-model="creatingNewsItem.body" :label="'Описание Ру'" :required="true"></new-textarea>
        <new-textarea v-model="creatingNewsItem.bodyKaz" :label="'Описание Каз'" :required="true"></new-textarea>
        <new-file-input :label="'Фото для новости'" :required="true" @uploadFilesToParentEl="setFiles"></new-file-input>

        <div class="dfsb">
          <main-button class="mt" @click.native="createNewsItem">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('close')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { postItems } from "@/api/lists";

export default {
  data() {
    return {
      fileArr: [],
      creatingNewsItem: {
        articleType: "1",
        title: "",
        titleKaz: "",
        body: "",
        bodyKaz: "",
      },
    };
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    reqURL() {
       return 'https://admin.sunkarpremium.kz/api/news/createArticle.php'
    },
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),

    setFiles(arr) {
      this.fileArr = arr;
    },
    createNewsItem() {
      let formData = new FormData();
      let catValues = Object.entries(this.creatingNewsItem);
      let emptyItems = [];
      for (let i = 0; i < catValues.length; i++) {
        if (!catValues[i][1]) {
          emptyItems.push(catValues[i][0]);
        }
        formData.append(catValues[i][0], catValues[i][1]);
      }

      if (emptyItems.length > 0 || !this.fileArr[0]) {
        alert("Присутствуют обязательные к заполнению, пустые поля");
      } else {
        formData.append('image', this.fileArr[0]);
        this.postContent(formData);

        formData = null;
        catValues = null;
        emptyItems = null;
      }
    },
    async postContent(item) {
      try {
        const response = await postItems(this.reqURL, item, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('authToken')
          }
        })
        if (response.status == 200) {
          this.ACT_setMainNotification({
            type: 'success',
            bool: true,
            message: response.data.message,
          });
          this.creatingNewsItem = {
            articleType: "1",
            title: "",
            titleKaz: "",
            body: "",
            bodyKaz: "",
          },
          this.fileArr = [];

          this.$emit('close');
          this.$emit('call');
        }
      } catch (e) {
        this.ACT_setMainNotification({
          type: 'failure',
          bool: 'true',
          message: e.response.data.message
        });
      } finally {}
    },
  },
}
</script>

<style scoped>
</style>
