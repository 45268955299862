<template>
  <div class="current-page__category-list-item category-list-item">
    <div class="category-list-item__label">
      <h6 class="category-list-item__title">{{ item.title }}</h6>
    </div>
    <div class="category-list-item__btn-group">
      <main-button class="category-list-item__btn" @click.native="activeEditPromoItem = item.id">Редактировать</main-button>
      <main-button class="category-list-item__btn" @click.native="activeRemovePromoItem = item.id">Удалить</main-button>
    </div>
  
    <edit-promo-item v-if="activeEditPromoItem === item.id" :item="item" @collapse="activeEditPromoItem = -1" @call="$emit('call')"></edit-promo-item>
    <remove-promo-item v-if="activeRemovePromoItem === item.id" :item="item" @collapse="activeRemovePromoItem = -1" @call="$emit('call')"></remove-promo-item>
  </div>
</template>

<script>
import editPromoItem from "@/components/newsPromoPage/promoItems/editPromoItem.vue";
import removePromoItem from "@/components/newsPromoPage/promoItems/removePromoItem.vue";

export default {
  components: {
    editPromoItem,
    removePromoItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      activeEditPromoItem: "-1",
      activeRemovePromoItem: "-1",
    };
  },
  methods: {
  }
};
</script>

<style scoped>
</style>