<template>
  <div class="modal" @click="$emit('close')">
    <div class="modal__body modal__body_wide" @click.stop>
      <h5 class="modal__title">Редактировать отзыв</h5>
      <button type="button" class="modal__close" @click="$emit('close')">
        <img src="@/assets/img/icons/regular/close-icon.svg" alt="close-icon">
      </button>

      <form action="#" class="modal__form" @submit.prevent>
        <new-input v-model="name" :label="'Имя'" :required="true"></new-input>
        <new-input v-model="email" :label="'Дата'" :required="true"></new-input>
        <new-textarea v-model="comment" :label="'Отзыв'" :required="true"></new-textarea>

        <div class="dfsb">
          <main-button class="mt" @click.native="edit()">Подтвердить</main-button>
          <main-button class="mt" @click.native="$emit('close')">Отмена</main-button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getItems } from "@/api/lists";

export default {
  data() {
    return {
      id: null,
      name: '',
      email: '',
      comment: '',
    }
  },
  methods: {
    ...mapMutations(["MUT_setIsLoading"]),
    ...mapActions(["ACT_setMainNotification"]),
    ...mapActions("reviews", ["editReview"]),
    edit() {
      const body = {
        id: this.current.id,
        name: this.name,
        email: this.email,
        comment: this.comment
      }
      this.editReview(body)
        .then(() => {
          this.$emit('close')
          this.clearFields()
        })
    },
    clearFields() {
      this.id = null
      this.name = ''
      this.email = ''
      this.comment = ''
    }
  },
  computed: {
    ...mapGetters("reviews", ["current"])
  },
  mounted() {
    this.id = this.current.id
    this.name = this.current.name
    this.email = this.current.email
    this.comment = this.current.comment
  },
  beforeDestroy() {
    this.clearFields()
  }
}
</script>