import { render, staticRenderFns } from "./pageListItem.vue?vue&type=template&id=0ae1c06b&scoped=true"
import script from "./pageListItem.vue?vue&type=script&lang=js"
export * from "./pageListItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae1c06b",
  null
  
)

export default component.exports